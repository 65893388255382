<template>
  <details-banner-layout>
    <!-- project icon -->
    <template v-slot:avatar>
      <project-icon :project="project"></project-icon>
    </template>

    <!-- project name -->
    <template v-slot:default>
      {{ title }}
    </template>

    <!-- subtitle -->
    <template v-slot:subtitle>
      <project-status :project="project"></project-status>
    </template>
  </details-banner-layout>
</template>

<script>
export default {
  name: "ProjectBanner",
  components: {
    DetailsBannerLayout: () =>
      import("@/components/shared/core/banners/DetailsBannerLayout"),
    ProjectIcon: () => import("@/components/project/ProjectIcon"),
    ProjectStatus: () => import("@/components/project/ProjectStatus")
  },
  props: {
    /**
     * @type {{projectId: Number, projectName:string, creationDate: string, definitionId: Number, workflowInstanceId: Number, workflowTypeId: Number, recordId: Number, subject: String, owner: String, userName:string, projectManagerId: Number, templateId: Number, templateName: String, status: number, isOverdue: Boolean, isSuspended: false, canAccess: Boolean, canCancel: Boolean, canChangeOwnership: Boolean, canReassign: Boolean, canSuspend: Boolean, comment:string, durableInstanceUid:string, creationDate:string, userTasks: {taskId: number, typeId: number, actorId: number, actorName: string, userName: string, assignee: string, canReassign: boolean, canceledRecipients: string, comment: string, dueDate: string, duration: string, isDue: boolean, isMilestone: boolean, isOverdue: boolean, priority: number, recordId: number, recordName: string, status: number, statusText: string, notes: {id: number, created: string, taskId: number, userId: number, userName: string}[]}}}
     */
    project: undefined
  },
  computed: {
    title() {
      return this.project?.projectName ?? "";
    }
  }
};
</script>
